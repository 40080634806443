// AOS
import AOS from 'aos';
import 'aos/dist/aos.css';
// アニメーションの設定
AOS.init({
    delay: 0,
    offset: 200,
    once: true,
    duration: 800,
});




import $ from "jquery";
// import SmoothScroll from 'smooth-scroll';

// const scroll = new SmoothScroll('a[href*="#"]', {
//     speed: 200,
//     offset: function (anchor, navigation) {
//         if (screen.width <= 640) {
//             return 80
//         } else {
//             return 55
//         }
//     },
//     easing: 'easeInOutQuint',
//     updateURL: false,
//     popstate: false
// })


const navopen = document.querySelector("#navopen");
const firstviewLayerNavopen = document.querySelector("#firstview_layer_navopen");
const navclose = document.querySelector("#navclose");
const navanker = document.querySelectorAll(".nav_main_list a");
const hamburger = document.querySelector("#hamburger");
const haschild = document.querySelectorAll(".haschild");

navopen?.addEventListener("click", (event) => {
    hamburger.classList.add("open");
})

// if(firstviewLayerNavopen){
    firstviewLayerNavopen?.addEventListener("click", (event) => {
        hamburger.classList.add("open");
    })
// }

navclose?.addEventListener("click", (event) => {
    hamburger.classList.remove("open");
})

navanker?.forEach(item => {
    if(!item.classList.contains("haschild")){
        item.addEventListener("click", (event) => {
            hamburger.classList.remove("open");
        })
    }
})

haschild?.forEach(item => {
    $(item).on("click", event => {
        if(window.matchMedia("(max-width:640px)").matches){
            $(event.currentTarget).toggleClass("open");
            $(event.currentTarget).next().slideToggle();
        }
    })
})



const faqItemDt = document.querySelectorAll(".faq_item dt");
faqItemDt?.forEach(item => {
    $(item).on("click", event => {
        $(event.currentTarget).toggleClass("open");
        $(event.currentTarget).next("dd").slideToggle();
    })
})





